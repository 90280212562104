import React, { FC, useRef } from 'react';

import {
  Box,
  Flex,
  Menu,
  Text,
  Button,
  MenuItem,
  MenuList,
  MenuButton,
  IconButton,
} from '@chakra-ui/react';

import {
  customStatusMap,
  customStatusColorsMap,
} from 'constants/assetStatusMap';
import { MdMoreVert } from 'react-icons/md';

import { ReportModal } from './ReportModal';

import SelectDayTimeModal from 'components/modules/SelectDayTimeModal';

import { CustomMission, CustomStatus } from 'types/mission';
import DeleteMissionButton from 'components/modules/DeleteMissionButton';
import { format } from 'date-fns';
import CancelAndDeleteMissionModal from 'components/modules/CancelAndDeleteMissionModal';
import { RefType } from 'components/modules/PilotRegistration/PilotCertificationModal/PilotCertificationModal.props';

type Props = {
  isEdit: boolean;
  isClient: boolean;
  status: CustomStatus;
  customMissionId?: string;
  isCreateMission?: boolean;
  customMission?: CustomMission;
  isDeleting?: boolean;
  onMissionDelete?: (missionId: string) => void;
};

const brandColor = '#6728BB';

export const Header: FC<Props> = ({
  status,
  isEdit,
  isClient,
  customMission,
  customMissionId,
  isCreateMission,
  isDeleting,
  onMissionDelete,
}) => {
  const modalRef = useRef<any>(null);
  const cancelAndDeleteMissionModalRef = useRef<RefType>(null);

  const _status = customStatusMap[status];

  const showMenu = [
    CustomStatus.CLIENT_REJECTED,
    CustomStatus.COMPLETED,
    CustomStatus.FOR_PAYMENT,
    CustomStatus.ORDERED,
    CustomStatus.PILOT_ACCEPTED,
    CustomStatus.PILOT_REJECTED,
  ].includes(status);

  const showCalendarButton = [
    CustomStatus.FOR_PAYMENT,
    CustomStatus.ORDERED,
    CustomStatus.PILOT_ACCEPTED,
    CustomStatus.CLIENT_CHANGE_REQUESTED,
    CustomStatus.PENDING_PAYMENT,
  ].includes(status);

  // const renderCancelAndDelete = () => {
  //   return (
  //     <>
  //       <Text
  //         cursor="pointer"
  //         fontSize="16px"
  //         my={1}
  //         textAlign="center"
  //         color="errorColor"
  //         fontWeight="bold"
  //         onClick={() => {
  //           cancelAndDeleteMissionModalRef.current?.onOpen?.();
  //         }}>
  //         Cancel and Delete
  //       </Text>
  //       <CancelAndDeleteMissionModal
  //         isCustomMission
  //         ref={cancelAndDeleteMissionModalRef}
  //         missionId={customMissionId || ''}
  //       />
  //     </>
  //   );
  // };

  return (
    <Flex
      flexWrap="wrap"
      borderBottom="1px solid #ccc"
      bg={
        isClient
          ? '#000'
          : status === CustomStatus.REQUESTED
          ? isEdit
            ? '#fff'
            : '#000'
          : status === CustomStatus.PILOT_ACCEPTED
          ? isEdit
            ? '#fff'
            : '#fff'
          : '#fff'
      }
      align="center">
      <Text
        flex={1}
        px={6}
        py={3}
        color={
          isClient
            ? '#fff'
            : status === CustomStatus.REQUESTED
            ? isEdit
              ? '#000'
              : '#fff'
            : status === CustomStatus.PILOT_ACCEPTED
            ? isEdit
              ? '#000'
              : '#908361'
            : '#908361'
        }
        fontSize="24px"
        fontWeight="bold">
        {isClient
          ? 'Mission Details'
          : status === CustomStatus.REQUESTED
          ? isEdit
            ? 'Create custom mission'
            : 'Mission Details'
          : status === CustomStatus.PILOT_ACCEPTED ||
            status === CustomStatus.CLIENT_CHANGE_REQUESTED
          ? isEdit
            ? 'Edit custom mission'
            : 'Custom mission sent'
          : 'Custom mission sent'}
      </Text>
      {showCalendarButton &&
      !!customMission &&
      !showMenu &&
      customMission.status !== CustomStatus.ORDERED ? (
        <Button variant="outline" alignItems="center">
          <AddToCalendar customMission={customMission} />
        </Button>
      ) : (
        <></>
      )}
      {onMissionDelete && customMission?.status === CustomStatus.REQUESTED && (
        <DeleteMissionButton
          isDeleting={!!isDeleting}
          onDelete={() => {
            onMissionDelete(customMissionId || '');
          }}
        />
      )}
      {!isCreateMission && (
        <Box bg="#fff" mx={4} borderRadius="5px">
          <Text
            px={5}
            py="2px"
            borderRadius="5px"
            bg={`${customStatusColorsMap[status]}20`}
            color={customStatusColorsMap[status]}
            fontSize="18px"
            fontWeight="bold">
            {_status}
          </Text>
        </Box>
      )}
      {showMenu && (
        <Menu>
          <MenuButton
            as={IconButton as any}
            mr={4}
            size="sm"
            variant="outline"
            aria-label="custom-mission-menu"
            icon={<MdMoreVert size="20px" />}
          />
          <MenuList py={0}>
            <MenuItem>
              <Box>
                {showCalendarButton && !!customMission && (
                  <AddToCalendar customMission={customMission} />
                )}
              </Box>
            </MenuItem>
            <MenuItem
              color={brandColor}
              fontWeight="bold"
              onClick={() => {
                modalRef?.current?.onOpen?.();
              }}>
              Report Mission
            </MenuItem>
            {/* {customMission?.status === CustomStatus.ORDERED && (
              <MenuItem>{renderCancelAndDelete()}</MenuItem>
            )} */}
          </MenuList>
        </Menu>
      )}
      {!!customMissionId && (
        <ReportModal ref={modalRef} customMissionId={customMissionId} />
      )}
    </Flex>
  );
};

const AddToCalendar: FC<{ customMission: CustomMission }> = ({
  customMission,
}) => {
  const selectDateTimeModalRef = useRef<any>(null);

  const getDescription = () => {
    const description = `\nPackage - ${
      customMission?.package?.name || 'N/A'
    }\n\nMission Details - ${
      customMission?.missionDetails || ''
    }\n\nAloft Data - ${customMission?.airspace?.overview?.short}.\n${
      customMission?.airspace?.overview?.full
    }`;

    return description;
  };

  return (
    <>
      <Text
        w="200px"
        fontWeight="bold"
        alignSelf="center"
        color={brandColor}
        onClick={() => {
          selectDateTimeModalRef.current?.onOpen?.();
        }}>
        Add to Calendar
      </Text>
      <SelectDayTimeModal
        ref={selectDateTimeModalRef}
        mission={
          {
            dueDate: customMission?.dueDate
              ? new Date(customMission?.dueDate)
              : new Date(0),
            eventStart: customMission?.eventStart
              ? new Date(customMission?.eventStart)
              : new Date(0),
          } as any
        }
        onSave={(startDate, endDate) => {
          const encodedStartDate = encodeURIComponent(
            format(new Date(startDate), "yyyyMMdd'T'HHmmss'Z'"),
          );
          const encodedEndDate = encodeURIComponent(
            format(new Date(endDate), "yyyyMMdd'T'HHmmss'Z'"),
          );

          const encodedLocation = encodeURIComponent(
            customMission.clientDetails?.location?.address,
          );
          const encodedDescription = encodeURIComponent(getDescription());
          const encodedSummary = encodeURIComponent(
            `DroneAdair Custom Mission requested by ${customMission?.clientDetails?.name}`,
          );

          const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedSummary}&dates=${encodedStartDate}/${encodedEndDate}&details=${encodedDescription}&location=${encodedLocation}&sf=true&output=xml`;

          window.open(url, '_blank');
        }}
      />
    </>
  );
};
