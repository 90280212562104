import React, { FC } from 'react';

import {
  Text,
  Input,
  Stack,
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import { SiteBlockerGeneratedProps } from './SiteBlocker.props';

const SiteBlockerView: FC<SiteBlockerGeneratedProps> = ({
  isOpen,
  password,
  onChange,
  onSubmit,
}) => {
  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={() => null}>
      <ModalOverlay bg="rgba(0,0,0,0.9)" />
      <ModalContent>
        <ModalBody zIndex={10}>
          <Stack p="10" spacing={4}>
            <Text fontWeight="bold" textAlign="center">
              Please Enter the Password to access the site
            </Text>
            <Input
              value={password}
              onChange={(e) => onChange(e.target.value)}
              placeholder="password"
              textAlign="center"
            />
            <Button variant="outline" onClick={onSubmit}>
              ENTER
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SiteBlockerView;
