import React, { FC, useState } from 'react';

import { useDisclosure, useToast } from '@chakra-ui/react';

import { SiteBlockerProps } from './SiteBlocker.props';

import SiteBlockerView from './SiteBlocker.view';

const DEV_PASSWORD = 'DroneAdair DEV';

const SiteBlockerContainer: FC<SiteBlockerProps> = () => {
  const toast = useToast();

  const [password, setPassword] = useState('');

  const isPasswordSaved = sessionStorage.getItem('isPasswordSaved');

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: !Boolean(isPasswordSaved),
  });

  const onSubmit = () => {
    if (password.trim() === '') {
      return;
    }

    if (password === DEV_PASSWORD) {
      sessionStorage.setItem('isPasswordSaved', 'true');
      onClose();
    } else {
      toast({
        status: 'error',
        title: 'Incorrect Password',
        description: 'Please try again',
        isClosable: true,
        duration: 5000,
      });
    }
  };

  return (
    <SiteBlockerView
      isOpen={isOpen}
      password={password}
      onSubmit={onSubmit}
      onChange={setPassword}
    />
  );
};

export default SiteBlockerContainer;
